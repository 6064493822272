import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import _ from "lodash"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Nav from "../components/Nav"

const PageFeatImg = ({ featuredImage }) => {
  if (!featuredImage) return <></>
  const size = _.find(featuredImage.node.mediaDetails.sizes, { name: "featured-thumb" })
  const src = size ? size.sourceUrl : featuredImage.node.sourceUrl
  return (
    <img alt={featuredImage.node.altText} src={src} className="absolute left-0 top-0 w-full h-full z-0 object-cover" />
  )
}

export default function Page({ data }) {
  const page = data.wpPage

  return (
    <Layout>
      <Seo post={page} />
      <Helmet>
        <title>{page.seo.title}</title>
      </Helmet>
      <Nav />
      <main className="mt-8">
        <div className="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative" style={{ height: "24em" }}>
          <div
            className="absolute left-0 bottom-0 w-full h-full z-10"
            style={{ backgroundImage: "linear-gradient(180deg,transparent,rgba(0,0,0,.9))" }}
          />
          <PageFeatImg featuredImage={page.featuredImage} />
          <div className="p-4 absolute bottom-0 left-0 z-20">
            <h1 className="text-4xl font-semibold text-gray-100 leading-tight">{page.title}</h1>
          </div>
        </div>
        <article
          className="p-8 lg:p-4 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed prose-lg"
          dangerouslySetInnerHTML={{ __html: page.content }}
          style={{ backgroundColor: "#f2f4f7" }}
        />
      </main>
    </Layout>
  )
}
export const query = graphql`
  query wpPage($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      title
      content
      featuredImage {
        node {
          altText
          sourceUrl
          mediaDetails {
            sizes {
              file
              height
              width
              sourceUrl
              name
            }
          }
        }
      }
      seo {
        title
        metaKeywords
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphImage {
          link
          mediaDetails {
            width
            height
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          link
        }
      }
      uri
    }
    wp {
      generalSettings {
        url
      }
    }
  }
`
