import React from "react"
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet";
import { get, isEmpty, reduce } from "lodash"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ post }) => {

  // const data = data.wp.generalSettings.url
    const data = useStaticQuery(
      graphql`
        query {
          wp {
            generalSettings {
              url
            }
          }
        }
      `
    )
    const { url:siteUrl } = data.wp.generalSettings
    const articleTags = ''
    if (typeof post.tags !== 'undefined' && post.tags.nodes.length > 0) {
        post.tags.nodes.map((tag) =>
          <meta property="article:tag" content={tag.name} />
        );
    }
    let categoryTag = ''
    if ( typeof post.categories !== 'undefined' && post.categories.nodes.length > 0) {
        categoryTag = <meta property="article:section" content={post.categories.nodes[0].name} />
    }
    const meta = [
      { property: "og:locale", content: "en_US" },
      { property: "og:type",  content: get(post, 'seo.opengraphType', '') },
      { property: "og:title", content: get(post, 'seo.opengraphTitle', '') },
      { property: "og:description", content: get(post, 'seo.opengraphDescription', '') },
      { property: "og:url", content: get(post, 'seo.opengraphUrl', '') },
      { property: "og:site_name", content: get(post, 'seo.opengraphSiteName', '') },
      { property: "article:modified_time", content: get(post, 'seo.opengraphModifiedTime', '') },
      { property: "article:published_time", content: get(post, 'seo.opengraphPublishedTime', '') },
      { property: "og:updated_time", content: get(post, 'seo.opengraphModifiedTime', '') },
      { property: "og:image", content: get(post, 'seo.opengraphImage.link', '') },
      { property: "og:image:secure_url", content: get(post, 'seo.opengraphImage.link', '') },
      { property: "og:image:width", content: get(post, 'seo.opengraphImage.mediaDetails.width', '') },
      { property: "og:image:height", content: get(post, 'seo.opengraphImage.mediaDetails.height', '') },
      { name: "twitter:description", content: get(post, 'seo.twitterDescription', '') },
      { name: "twitter:title", content: get(post, 'seo.twitterTitle', '') },
      {
        name: "twitter:image",
        content: (
          get(post, 'seo.twitterImage', false) ?
            get(post, 'seo.twitterImage.link') :
            get(post, 'seo.opengraphImage.link')
          ),
      },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: "@nullvariable" },
      { name: "twitter:creator", content: "@nullvariable" },
    ]
    const metaTags = reduce(meta, (final, item, index) => {
      if ( ! isEmpty(item.content) ) {
        const metaItem = <meta key={index} name={item.name} property={item.property} content={item.content} />
        final.push(metaItem)
      }
      return final
    }, [])
    const uri = get(post, 'uri', '')
    let canonical = ''
    if ( ! isEmpty(uri) ) {
      canonical = <link rel="canonical" href={`${siteUrl}${uri}`} />
    }
    return (
      <Helmet>
        {canonical}
        {metaTags}
        {articleTags}
        {categoryTag}
      </Helmet>
    )
}

Seo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  post: PropTypes.object.isRequired,
}

export default Seo
